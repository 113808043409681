import { defineStore } from "pinia";
import caas from "@/services/caas";
import i18n from "@/plugins/i18n";
import { auth } from "@/services/firebase";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import navigation from "@/store/navigation.json";

export const useSessionStore = defineStore({
    id: "session",

    state: () => ({
        authenticated: false,
        initialized: false,
        navigation: navigation.navItems,
        notification: {
            isVisible: false,
            success: false,
            error: "",
        },
        log: [],
        fiscalYear: 0,
        fiscalYears: [],
        fiscalPeriods: [],
        userId: null
    }),

    actions: {
        logger(msg) {
            if (process.env.VUE_APP_LOGGER) {
                let entry = {
                    timestamp: new Date(),
                    msg: msg,
                };
                this.log.push(entry);
                //console.log(msg);
            }
        },

        async authenticate(userId) {
            if (userId) {
                this.authenticated = true
                this.userId = userId
            } else {
                this.authenticated = false
                this.userId = null
            }

            return
        },

        async initialize() {
            if (!this.initialized) {
                await this.fetchFiscalYears()
                await this.fetchFiscalYear()
                this.initialized = true
            }
            return
        },

        showSuccess() {
            this.notification = {
                isVisible: true,
                success: true,
                error: "",
            };
            var timeout = 2500,
                that = this;
            window.setTimeout(function () {
                that.hideNotification();
            }, timeout);
        },

        showError(_error) {
            this.notification = {
                isVisible: true,
                success: false,
                error: _error,
            };
            var timeout = 10000,
                that = this;
            window.setTimeout(function () {
                that.hideNotification();
            }, timeout);
        },

        hideNotification() {
            this.notification = {
                isVisible: false,
                success: false,
                error: "",
            };
        },

        login(u, p) {
            return signInWithEmailAndPassword(auth, u, p);
        },

        async logout() {
            return await signOut(auth);
        },

        async fetchFiscalYears() {
            const response = await caas.rpc("getFiscalYears");
            this.fiscalYears = response.data.data.fiscalYears;
            return this.fiscalYears.sort((a, b) => b.fiscalYear - a.fiscalYear);
        },

        async fetchFiscalYear() {
            if (this.fiscalYear == 0) {
                this.fiscalYear =
                    localStorage.getItem("fiscalYear") ||
                    this.fiscalYears[0].fiscalYear;
            }
            if (this.fiscalYear > 0) {
                const res = await caas.rpc("getFiscalPeriods", {
                    year: Number(this.fiscalYear),
                });
                return (this.fiscalPeriods =
                    res.data.data.fiscalPeriods.map((v) => {
                        let date1 = i18n.d(new Date(v.van), "short");
                        let date2 = i18n.d(new Date(v.tot), "short");
                        let text = date1 + " - " + date2;
                        return { text: text, value: v.periode };
                    }));
            }
        },
    },
    getters: {
        navigationItems: (state) => {
            return state.navigation
        },
        lastLogEntry: (state) => {
            let logEntry = state.log.length;
            if (logEntry) return state.log[logEntry - 1].msg;
        },
    },
});
