<template>
	<v-app id="app">

		<!-- NAVDRAWER  -->
		<v-navigation-drawer app v-model="drawer" disable-resize-watcher>
			<v-toolbar flat>
				<v-toolbar-title>
					{{ tenantName }}
				</v-toolbar-title>
			</v-toolbar>
			<smart-nav-bar vertical />
		</v-navigation-drawer>

		<!-- APP BAR -->
		<v-app-bar app elevation="1" color="white" v-show="session.authenticated">
			<v-app-bar-nav-icon v-if="!horizontal" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
			<v-toolbar-title>
				{{ tenantName }}
			</v-toolbar-title>
			<v-combobox style="max-width: 90px" class="font-weight-medium" v-model="fiscalYear" :return-object="false"
				:items="session.fiscalYears" item-text="fiscalYear" item-value="fiscalYear" type="Number" hide-details
				hide-spin-buttons dense solo flat />
			<smart-nav-bar class="kmp" v-if="horizontal" />
			<v-spacer />
			<v-icon @click.stop="logout()">mdi-exit-to-app</v-icon>
		</v-app-bar>

		<!-- MAIN -->
		<v-main v-scroll="onScroll" v-resize="onResize" class="grey lighten-4">


			<transition name="fade" mode="out-in">
				<keep-alive>
					<router-view v-resize="onResize"> </router-view>
				</keep-alive>
			</transition>

		</v-main>

		<!-- NOTIFICATIONS -->
		<v-bottom-sheet :value="session.notification.isVisible" hide-overlay width="auto">
			<v-btn v-if="session.notification.success" icon large fab
				@click="session.notification.isVisible = false"><v-icon color="success">mdi-thumb-up</v-icon></v-btn>
			<v-sheet v-if="session.notification.error" class="mx-auto" max-height="150px" dark><v-container>
					<v-row align="center">
						<v-col cols="auto">
							{{ session.notification.error }}
						</v-col>
						<v-col cols="1">
							<v-btn icon
								@click="session.notification.isVisible = false"><v-icon>mdi-close-circle-outline</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</v-sheet>
		</v-bottom-sheet>

		<!-- SCROLL TOP -->
		<v-btn class="mb-4" v-if="this.scroller" style="position: fixed; bottom: 0; right: 0" large color="info" icon
			v-on:click.stop="$vuetify.goTo(0)">
			<v-icon>mdi-arrow-up-thin-circle-outline</v-icon>
		</v-btn>
	</v-app>
</template>

<script>
import lodash from "lodash";
import SmartNavBar from "./components/SmartNavBar.vue";
import { useSessionStore } from "@/store/session";
import router from "./router";

export default {
	name: "App",

	components: { SmartNavBar },

	data: () => ({
		drawer: false,
		scroller: false,
		tenantName: process.env.VUE_APP_TENANT_NAME,
		session: useSessionStore()
	}),

	computed: {
		fiscalYear: {
			get() {
				return this.session.fiscalYear;
			},
			set(year) {
				localStorage.setItem("fiscalYear", year);
				location.reload();
			},
		},

		horizontal() {
			return this.$vuetify.breakpoint.mdAndUp;
		},
	},

	methods: {
		onScroll() {
			this.scroller = window.pageYOffset > 256;
		},

		onResize() {
			let fx = lodash.debounce(this.setContentHeight, 500);
			fx();
		},

		setContentHeight() {
			this.session.contentHeight =
				window.innerHeight -
				this.$vuetify.application.top -
				this.$vuetify.application.footer;
		},

		async logout() {
			await this.session.logout()
			router.push({ name: "Login" })
		}
	},
};
</script>

<style>
	/* POSITIONING */
	.center-center {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.kmp {
		position: fixed;
		left: 50%;
		transform: translate(-50%, 0%);
	}

	/*** SCROLLBAR ***/
	/* width */
	::-webkit-scrollbar {
		width: 8px;
	}
	/* Track */
	::-webkit-scrollbar-track {
		background: #eceff1; /* blue-grey lighten-5  */
	}
	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #b0bec5; /* blue-blue-grey lighten-5  */
	}
	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #78909c; /* blue-grey lighten-1 */
	}

	/*** TRANSITIONS ***/
	.fade-enter {
		opacity: 0;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.5s ease-out;
	}

	.fade-leave-to {
		opacity: 0;
	}

	.slide-fade-enter {
		transform: translateX(10px);
		opacity: 0;
	}

	.slide-fade-enter-active,
	.slide-fade-leave-active {
		transition: all 0.25s ease;
	}

	.slide-fade-leave-to {
		transform: translateX(-10px);
		opacity: 0;
	}
</style>
