import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import pinia from "./plugins/pinia";
import i18n from "./plugins/i18n";
import router from "./router";
import { useSessionStore } from "@/store/session";
import { auth } from "@/services/firebase";

auth.onAuthStateChanged(async (user) => {
    const session = useSessionStore()
    await session.authenticate(user ? user.uid : null)
    await session.initialize()

});

Vue.config.productionTip = false;

new Vue({
    pinia,
    router,
    vuetify,
    i18n,
    render: (h) => h(App)
}).$mount("#app");
