import Vue from "vue";
import VueCompositionApi from "@vue/composition-api";
import { createPinia, PiniaVuePlugin } from "pinia";

Vue.use(VueCompositionApi);
Vue.use(PiniaVuePlugin);

const pinia = createPinia();
Vue.use(pinia);

export default pinia;
