import axios from "axios";

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT,
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

const rpc = async (rpcName, params) => {

    // inject tenantId into params
    params = params || {};
    params.tenantId = Number(process.env.VUE_APP_TENANT_ID);

    // go!
    const oBody = { request: { rpcName, params } };
    const response = await apiClient.post("/rpc", oBody);
    return response;
};

const sdo = async (sdoName, params) => {

    // inject tenantId into params
    params = params || {};
    params.tenantId = Number(process.env.VUE_APP_TENANT_ID);

    // go!
    const oBody = { request: { sdoName, params } };
    const response = await apiClient.post("/sdo", oBody);
    return response;
};

export default {
    rpc,
    sdo,
};
