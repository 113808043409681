import { initializeApp } from "firebase/app";
import { initializeAuth, browserLocalPersistence } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

// init app
const firebaseConfig = {
    apiKey: process.env.VUE_APP_FB_API_KEY,
    authDomain: process.env.VUE_APP_FB_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FB_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FB_APP_ID,
};
const app = initializeApp(firebaseConfig);

// add auth to app
const auth = initializeAuth(app, {
    persistence: browserLocalPersistence,
});

const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app, "europe-west1");

// b2a service calls
const b2aService = async (request) => {

    try {
        // generic call handler
        const call = httpsCallable(functions, "b2a-call");

        // inject tenantId
        request.params.tenantId = process.env.VUE_APP_TENANT_ID;

        // call service
        const response = await call(request);

        if (response.error) {
            throw new Error(response.error);
        }

        return response;

    } catch (error) {
        console.error('b2aService: ', error);
        throw new Error(error);
    }
};

export { auth, db, storage, functions, b2aService };
