import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home";
import Login from "@/views/Login"
import goTo from 'vuetify/lib/services/goto'
import { useSessionStore } from "@/store/session";

Vue.use(VueRouter);

function lazyLoad(view) {
    return () => {
        return import(`@/views/${view}.vue`);
    };
}

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: { requiresAuth: true }
    },
    {
        path: "/login",
        name: "Login",
        component: Login
    },
    {
        path: "/resetPassword",
        name: "ResetPassword",
        component: lazyLoad("ResetPassword"),
        meta: { requiresAuth: true }
    },
    {
        path: "/journals/purchaseJournal",
        name: "PurchaseJournal",
        component: lazyLoad("PurchaseJournal"),
        meta: { requiresAuth: true }
    },
    {
        path: "/journals/salesJournal",
        name: "SalesJournal",
        component: lazyLoad("SalesJournal"),
        meta: { requiresAuth: true }
    },
    {
        path: "/journals/financialJournal",
        name: "FinancialJournal",
        component: lazyLoad("FinancialJournal"),
        meta: { requiresAuth: true }
    },
    {
        path: "/journals/generalJournal",
        name: "GeneralJournal",
        component: lazyLoad("GeneralJournal"),
        meta: { requiresAuth: true }
    },
    {
        path: "/journals/openingJournal",
        name: "OpeningJournal",
        component: lazyLoad("OpeningJournal"),
        meta: { requiresAuth: true }
    },
    {
        path: "/balances/balanceSheet",
        name: "BalanceSheet",
        component: lazyLoad("BalanceSheet"),
        meta: { requiresAuth: true }
    },
    {
        path: "/balances/accountsReceivable",
        name: "ARBalanceSheet",
        component: lazyLoad("ARBalanceSheet"),
        meta: { requiresAuth: true }
    },
    {
        path: "/balances/accountsPayable",
        name: "APBalanceSheet",
        component: lazyLoad("APBalanceSheet"),
        meta: { requiresAuth: true }
    },
    {
        path: "/declarations/vatDeclaration",
        name: "VatDeclaration",
        component: lazyLoad("VatDeclaration"),
        meta: { requiresAuth: true }
    },
    {
        path: "/declarations/icDeclaration",
        name: "IcDeclaration",
        component: lazyLoad("IcDeclaration"),
        meta: { requiresAuth: true }
    },
    {
        path: "/declarations/vatListing",
        name: "VatListing",
        component: lazyLoad("VatListing"),
        meta: { requiresAuth: true }
    },
    {
        path: "/generalLedger/:ledgerId",
        name: "GeneralLedger",
        component: lazyLoad("GeneralLedger"),
        meta: { requiresAuth: true }
    },
    {
        path: "/arLedger/:customerId",
        name: "ARLedger",
        component: lazyLoad("ARLedger"),
        meta: { requiresAuth: true }
    },
    {
        path: "/apLedger/:supplierId",
        name: "APLedger",
        component: lazyLoad("APLedger"),
        meta: { requiresAuth: true }
    },
];

const scrollBehavior = function (to, from, savedPosition) {
    let scrollTo = 0;
    if (to.hash) {
        scrollTo = to.hash;
    } else if (savedPosition) {
        scrollTo = savedPosition.y;
    }
    return setTimeout(function () {
        goTo(scrollTo);
    }, 500);
};

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior
});

router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title =
            to.meta.title ||
            process.env.VUE_APP_TENANT_NAME +
            " " +
            process.env.VUE_APP_APP_NAME;
    });
});

// navigation guards
router.beforeEach((to, from, next) => {

    const session = useSessionStore()

    if (to.meta && to.meta.requiresAuth) {


        if (session.initialized) {
            if (session.authenticated) {
                next()
            } else {
                next({ name: "Login" })
            }
        }
        else {
            // Set up a manual watcher on the Pinia store
            const interval = setInterval(() => {
                if (session.initialized) {
                    clearInterval(interval);
                    // Proceed with the navigation
                    if (session.authenticated) {
                        next()
                    } else {
                        next({ name: "Login" })
                    }
                }
            }, 100); // Check every 100 milliseconds
        }
    }
    else {
        next()
    }
})


export default router;
