<template>
	<div>
		<!-- VERTICAL -->
		<v-row no-gutters v-if="vertical">
			<v-col cols="12">
				<v-list dense>
					<div v-for="navItem in session.navigation" :key="navItem.id">
						<v-list-item v-if="!navItem.children" router :to="{ name: navItem.routeName }" exact
							active-class="primary--text">
							<v-list-item-icon>
								<v-icon v-text="navItem.icon"></v-icon>
							</v-list-item-icon>
							<v-list-item-title v-text="navItem.label" />
						</v-list-item>
						<v-list-group v-else :prepend-icon="navItem.icon" no-action>
							<template v-slot:activator>
								<v-list-item-title v-text="navItem.label" />
							</template>
							<v-list-item v-for="_navItem in navItem.children" :key="_navItem.id" router
								:to="{ name: _navItem.routeName }" active-class="primary--text">
								<v-list-item-title v-text="_navItem.label" />
							</v-list-item>
						</v-list-group>
					</div>
				</v-list></v-col></v-row>
		<!-- HORIZONTAL -->
		<v-row no-gutters v-if="!vertical">
			<v-col cols="auto" v-for="navItem in session.navigation" :key="navItem.id">
				<v-menu v-if="navItem.children" bottom offset-y tile open-on-hover>
					<template v-slot:activator="{ on, attrs }">
						<v-btn v-bind="attrs" v-on="on" :to="{ name: navItem.routeName }" exact plain height="64">
							<v-icon large>{{ navItem.icon }}</v-icon>
							<span class="hidden-md-and-down">{{
								navItem.label
							}}</span>
						</v-btn>
					</template>
					<v-list class="py-0">
						<v-list-item v-for="_navItem in navItem.children" :key="_navItem.id" router
							:to="{ name: _navItem.routeName }" active-class="primary--text">
							{{ _navItem.label }}
						</v-list-item>
					</v-list>
				</v-menu>
				<v-btn v-else-if="navItem.routeName" :to="{ name: navItem.routeName }" active-class="primary--text" exact
					plain height="64"><v-icon v-if="navItem.icon" large>{{
						navItem.icon
					}}</v-icon>
					<span class="hidden-md-and-down">
												{{ navItem.label }}
											</span>
				</v-btn>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { useSessionStore } from "@/store/session";
export default {
	name: "SmartNavBar",
	props: {
		vertical: Boolean,
	},
	data() {
		return {
			session: useSessionStore()
		}
	}

};
</script>

<style>
</style>