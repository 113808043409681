<template>
	<v-card width="600" max-width="100vw" flat outlined class="center-center">
		<v-card-title>Dossier</v-card-title>
		<v-divider />
		<v-card-text>
			<v-data-table disable-sort disable-pagination hide-default-footer :items="dossier" :headers="headers"
				mobile-breakpoint="9999">
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>
import caas from "@/services/caas";
export default {
	name: "Home",
	data() {
		return {
			loading: false,
			dossier: [],
			headers: [
				{ text: "Naam", value: "naam" },
				{ text: "Adres", value: "adres" },
				{ text: "Postcode", value: "postcode" },
				{ text: "Gemeente", value: "gemeente1" },
				{ text: "Landcode", value: "landcode" },
				{ text: "Tel.", value: "tel" },
				{ text: "E-mail", value: "email" },
				{ text: "Website", value: "website" },
				{ text: "Btwnr", value: "btwnr" },
			],
		};
	},
	computed: {
		height() {
			return (
				window.innerHeight -
				this.$vuetify.application.top -
				this.$vuetify.application.footer
			);
		},
	},
	methods: {
		async fetchDossierInfo() {
			const sdoName = "boek/objects/dDossier.r";
			let params = {
				action: "read",
				nbrOfRows: 1,
				setOpenQuery: "for each dossier no-lock indexed-reposition",
			};

			this.loading = true;
			const response = await caas.sdo(sdoName, params);
			this.dossier = response.data.data.RowObject;
			this.loading = false;
		},
	},
	mounted() {
		this.fetchDossierInfo();
	},
};
</script>

<style>
</style>